/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import { COLUMN, OPTIONS, createDealDetail } from './utils';
import { DealApi, UserClient } from '../../services/api';

const DealDetail = function (): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();
  const { getDeals } = DealApi();
  const { getUserById } = UserClient();
  const title = t('detail_deal');
  const [data, setData] = useState<any[]>([]);
  const [dealDetails, setDealDetails] = useState({});

  useEffect(() => {
    (async () => {
      const deals = await getDeals();
      const deal = deals.find((deal: any) => deal.id == id);
      const user = await getUserById(deal.creatorId);
      setData(createDealDetail(deal, user, t));
      setDealDetails(deal);
    })();
  }, [id]);
 
  return (
    <React.Fragment>
      <h1 style={{ textAlign: 'center' }}>{title}</h1>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '20px' }}>
        <div style={{ width: '40%', marginRight: '2%' }}>
          <MUIDataTable
            title={''}
            data={data}
            columns={COLUMN(dealDetails)}
            options={OPTIONS(t)}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DealDetail;
