import { getOfferStatus } from '../DealComponent/utils';

export const COLUMN = (deal: any) => [
  {
    name: 'name',
    label: '',
    options: {
      customHeadRender: ({ index }) => (
        <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
      )
    }
  },
  {
    name: 'data',
    label: '',
    options: {
      customHeadRender: ({ index }) => (
        <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
      ),
      customBodyRender: (
        value: any,
        tableMeta: any,
        updateValue: (arg0: any) => void
      ): JSX.Element => (
        <>
          {
            tableMeta.rowIndex === 5
            ? <p style={{cursor: 'pointer'}} onClick={() => {window.open(`/parcel/details/${deal.parcelId}`, '_blank') }}>{value}</p>
            : tableMeta.rowIndex === 6
            ? <p style={{cursor: 'pointer'}} onClick={() => {window.open(`/trip/details/${deal.tripId}`, '_blank') }}>{value}</p>
            : <p>{value}</p>
          }
        </>
      )
    }
  }
];

export const createDealDetail = (
  deal: any,
  user: any,
  t: any
): { name: string; data: any }[] => {
  return [
    {
      name: t('fio'),
      data: `${user?.firstName ?? ''} ${user?.lastName ?? ''} ${user?.middleName ?? ''}`.trim()
    },
    {
      name: t('parcel_title'),
      data: deal.parcel?.parcelTitle ?? ''
    },
    {
      name: t('trip_title'),
      data: deal.trip?.tripTitle ?? ''
    },
    {
      name: t('sum_offer'),
      data: `${deal?.price ?? '-'} ${deal?.price ? deal?.currency : ''}`
    },
    {
      name: t('offer_status'),
      data: getOfferStatus(deal.status, t),
    },
    {
      name: t('parcelId_sender'),
      data: deal.parcelId
    },
    {
      name: t('id_travel'),
      data: deal.tripId
    },

  ];
};



export const OPTIONS: any = (t: any) => {
  return {
    filter: false,
    responsive: 'vertical',
    draggableColumns: {
      enabled: true
    },
    print: false,
    download: false,
    search: false,
    sort: false,
    viewColumns: false,
    selectableRows: 'none',
    pagination: false,
    textLabels: {
      body: {
        noMatch: t('noMatch'),
        toolTip: t('sort'),
        columnHeaderTooltip: (column: any) => `${t('sort_by')} ${column.label}`
      },
      pagination: {
        next: t('next_page'),
        previous: t('previous'),
        rowsPerPage: t('rowsPerPage'),
        displayRows: t('a_from')
      },
      toolbar: {
        search: t('search'),
        downloadCsv: t('downloadCsv'),
        print: t('print'),
        viewColumns: t('viewColumn'),
        filterTable: t('filterTable')
      },
      filter: {
        all: t('all'),
        title: t('filters'),
        reset: t('reset')
      },
      viewColumns: {
        title: t('showColumns'),
        titleAria: t('show_hide_table_columns')
      },
      selectedRows: {
        text: t('row_selected'),
        delete: t('delete'),
        deleteAria: t('delete_area')
      }
    }
  };
};
