import moment from "moment";

export const customMarkers = [
  {
    id: 1,
    fio: "Pavel",
    placeName: "Moscow, Russia",
    position: { lat: 55.7558, lng: 37.6173 }
  },
  {
    id: 2,
    fio: "Ivan",
    placeName: "Tula, Russia",
    position: { lat: 54.2048, lng: 37.6185 }
  },
  {
    id: 3,
    fio: "Armen",
    placeName: "Yerevan, Armenia",
    position: { lat: 40.1872, lng: 44.5152 }
  },
  {
    id: 4,
    fio: "Davit",
    placeName: "Gyumri, Armenia",
    position: { lat: 40.7929, lng: 43.8465 }
  }
];

export const createMarkers = (users: any, t: any) => {
  return users.items.map((user: any, inx: number) => {
    return {
      id: inx + 1,
      userId: user.id,
      fio: `${user.firstName ? user.firstName + ' ' : ''}${user.middleName ? user.middleName + ' ' : ''}${user.lastName ? user.lastName + ' ' : ''}`,
      avatar: user.avatarUrl ?? 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg',
      accountType: t(user.accountType.toLowerCase()) ?? '',
      lastModified: moment(user.lastModifiedTS).format('DD.MM.YYYY HH:mm').toString(),
      position: {
        lat: user.lastKnownCoordinates[user.lastKnownCoordinates.length -1]?.lat ?? 0.0,
        lng: user.lastKnownCoordinates[user.lastKnownCoordinates.length -1]?.long ?? 0.0
      }
    }
  })
};

export const createUserMarkers = (user: any, t: any) => {
  return user.lastKnownCoordinates.map((location: any, inx: number) => {
    return {
      id: inx + 1,
      userId: user.id,
      fio: `${user.firstName ? user.firstName + ' ' : ''}${user.middleName ? user.middleName + ' ' : ''}${user.lastName ? user.lastName + ' ' : ''}`,
      avatar: user.avatarUrl ?? '',
      accountType: t(user.accountType.toLowerCase()) ?? '',
      lastModified: moment(user.lastModifiedTS).format('DD.MM.YYYY HH:mm').toString(),
      position: {
        lat: location?.lat ?? 0.0,
        lng: location?.long ?? 0.0
      }
    }
  })
};
