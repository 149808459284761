/* eslint-disable import/no-extraneous-dependencies */
import { Route, Switch, Redirect } from 'react-router-dom';
import { Login } from '../components/Login';
import { APP_ROUTE } from '../constants/route';
import { withPrivateRoute } from './withPrivateRoute';
import PopularCitiesComponent from 'components/PopularCitiesComponent/PopularCitiesComponent';
import TripComponent from '../components/TripComponent/TripComponent';
import ParcelComponent from '../components/ParcelComponent/ParcelComponent';
import UserComponent from '../components/UserComponent/UserComponent';
import UserDetail from '../components/UserDetail/UserDetail';
import ParcelDetail from '../components/ParcelDetail/ParcelDetail';
import TripDetail from '../components/TripDetail/TripDetail';
import { useApp } from '../context/AppContext';
import AddPopularCity from 'components/AddPopularCity/AddPopularCity';
import FeedBackComponent from 'components/FeedbackComponent/FeedbackComponent';
import CarsComponent from 'components/CarsComponent/CarsComponent';
import CarDetail from 'components/CarDetail/CarDetail';
import VerificationRequests from 'components/VerificationRequests/VerificationRequests';
import BlackListScreen from 'components/BlackList/BlackList';
import VerificationTripRequestDetail from 'components/VerificationTripRequestDetail/VerificationTripRequestDetail';
import BlackListDetail from 'components/BlackListDetail/BlackListDetail';
import VerificationUserRequestDetail from 'components/VerificationUserRequestDetail/VerificationUserRequestDetail';
import VerificationCarRequestDetail from 'components/VerificationCarRequestDetail/VerificationCarRequestDetail';
import PayoutComponent from 'components/PayoutComponent/PayoutComponent';
import MapsComponent from 'components/MapsComponent/MapsComponent';
import DealComponent from 'components/DealComponent/DealComponent';
import NotificationsComponent from 'components/NotificationsComponent/NotificationsComponent';
import DealDetail from 'components/DealDetail/DealDetail';

export const AppRoute = (): JSX.Element => {
  localStorage.setItem('parcelPaginationIndex', '0');
  localStorage.setItem('dealPaginationIndex', '0');
  localStorage.setItem('userPaginationIndex', '0');

  const { isAuthenticated } = useApp();

  return (
    <Switch>
      <Route path={APP_ROUTE.ROOT} exact component={() => <Redirect to={APP_ROUTE.PARCEL} />} />
      <Route path={APP_ROUTE.LOGIN} component={Login} />
      <Route
        path={APP_ROUTE.USER}
        exact
        component={withPrivateRoute(isAuthenticated, () => <UserComponent />)()}
      />
      <Route
        path={APP_ROUTE.MAIN}
        component={withPrivateRoute(isAuthenticated, () => <UserComponent />)()}
      />
      <Route
        path={APP_ROUTE.PARCEL}
        exact
        component={withPrivateRoute(isAuthenticated, () => <ParcelComponent />)()}
      />
      <Route
        path={APP_ROUTE.FEEDBACK}
        exact
        component={withPrivateRoute(isAuthenticated, () => <FeedBackComponent />)()}
      />
      <Route
        path={APP_ROUTE.TRIP}
        exact
        component={withPrivateRoute(isAuthenticated, () => <TripComponent />)()}
      />
      <Route
        path={APP_ROUTE.POPULAR_CITIES}
        exact
        component={withPrivateRoute(isAuthenticated, () => <PopularCitiesComponent />)()}
      />
      <Route
        path={APP_ROUTE.ADD_POPULAR_CITY}
        exact
        component={withPrivateRoute(isAuthenticated, () => <AddPopularCity />)()}
      />
      <Route
        path={APP_ROUTE.CAR}
        exact
        component={withPrivateRoute(isAuthenticated, () => <CarsComponent />)()}
      />
      <Route
        path={APP_ROUTE.CAR_DETAILS}
        exact
        component={withPrivateRoute(isAuthenticated, () => <CarDetail />)()}
      />
      <Route
        path={APP_ROUTE.USER_DETAILS}
        exact
        component={withPrivateRoute(isAuthenticated, () => <UserDetail />)()}
      />
      <Route
        path={APP_ROUTE.TRIP_DETAILS}
        exact
        component={withPrivateRoute(isAuthenticated, () => <TripDetail />)()}
      />
      <Route
        path={APP_ROUTE.PARCEL_DETAILS}
        exact
        component={withPrivateRoute(isAuthenticated, () => <ParcelDetail />)()}
      />
      <Route
        path={APP_ROUTE.BLACK_LIST}
        exact
        component={withPrivateRoute(isAuthenticated, () => <BlackListScreen />)()}
      />
      <Route
        path={APP_ROUTE.VERIFICATION_REQUESTS}
        exact
        component={withPrivateRoute(isAuthenticated, () => <VerificationRequests />)()}
      />
      <Route
        path={APP_ROUTE.DETAIL_VERIFICATION_TRIP}
        exact
        component={withPrivateRoute(isAuthenticated, () => <VerificationTripRequestDetail />)()}
      />
      <Route
        path={APP_ROUTE.DETAIL_VERIFICATION_USER}
        exact
        component={withPrivateRoute(isAuthenticated, () => <VerificationUserRequestDetail />)()}
      />
      <Route
        path={APP_ROUTE.DETAIL_VERIFICATION_CAR}
        exact
        component={withPrivateRoute(isAuthenticated, () => <VerificationCarRequestDetail />)()}
      />
      <Route
        path={APP_ROUTE.DETAIL_BLACK_LIST}
        exact
        component={withPrivateRoute(isAuthenticated, () => <BlackListDetail />)()}
      />
      <Route
        path={APP_ROUTE.PAYOUT}
        exact
        component={withPrivateRoute(isAuthenticated, () => <PayoutComponent />)()}
      />
      <Route
        path={APP_ROUTE.MAP}
        exact
        component={withPrivateRoute(isAuthenticated, () => <MapsComponent />)()}
      />
      <Route
        path={APP_ROUTE.USER_LOCATIONS_MAP}
        exact
        component={withPrivateRoute(isAuthenticated, () => <MapsComponent />)()}
      />
      <Route
        path={APP_ROUTE.DEAL}
        exact
        component={withPrivateRoute(isAuthenticated, () => <DealComponent />)()}
      />
      <Route
        path={APP_ROUTE.DEAL_DETAILS}
        exact
        component={withPrivateRoute(isAuthenticated, () => <DealDetail />)()}
      />
      <Route
        path={APP_ROUTE.NOTIFICATIONS}
        exact
        component={withPrivateRoute(isAuthenticated, () => <NotificationsComponent />)()}
      />
    </Switch>
  );
};
