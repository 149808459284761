/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from "react";
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from 'react-router-dom';
import { UserClient } from "services/api";
import { customMarkers, createMarkers, createUserMarkers } from "./utils";

const containerStyle = {
  width: '100%',
  height: '750px'
};

const GoogleMapsComponent = (): JSX.Element => {

  const { t } = useTranslation();
  const { userId } = useParams();
  const history = useHistory();
  const { getUserAll, getUserById } = UserClient();
  const TITLE = t("google_maps");
  const [markers, setMarkers] = useState<any>(customMarkers);
  const [activeMarker, setActiveMarker] = useState<number>(-1);
  const [centerPosition, setCenterPosition] = useState<{lat: number, lng: number}>({ lat: 40.7929, lng: 43.8465 });

  useEffect(() => {
    (async () => {
      if (userId) {
        const user = await getUserById(userId);
        const userMarkers = createUserMarkers(user, t);
        if (userMarkers) setMarkers(userMarkers);
        setCenterPosition(userMarkers[0].position);
      } else {
        const users = await getUserAll();
        const userMarkers = createMarkers(users, t);
        if (userMarkers) setMarkers(userMarkers);
      }
    })();
  }, [])

  const handleActiveMarker = (markerId: number) => {
    if (markerId === activeMarker) {
      return;
    }
    setActiveMarker(markerId);
  };

  const handleOnLoad = (map: any) => {
    const bounds = new google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  const goToUserMap = (userId: number) => history.push(`/map/${userId}`);

  return (
    <div>
      <h1>{TITLE}</h1>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={centerPosition}
          zoom={10}
          onLoad={handleOnLoad}
        >
          {markers.map(({ id, userId, fio, avatar, accountType, lastModified, position }) => (
            <Marker
              key={id}
              position={position}
              onClick={() => handleActiveMarker(id)}
            >
              {activeMarker === id ? (
                <InfoWindow onCloseClick={() => setActiveMarker(-1)}>
                  <>
                    <img src={avatar} alt="avatar" width="100" height="100"></img>
                    <p><b>{t('fio') + ': '}</b><span style={{cursor:'pointer'}} onClick={() => goToUserMap(userId)}>{fio}</span></p>
                    <p><b>{t('role') + ': '}</b><span>{accountType}</span></p>
                    <p><b>{t('last_activation') + ': '}</b><span>{lastModified}</span></p>
                  </>
                </InfoWindow>
              ) : null}
            </Marker>
          ))}
        </GoogleMap>
    </div>
  );
}

export default GoogleMapsComponent;
